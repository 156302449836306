import { createContext, useReducer } from "react";
import { AlertVariant } from "@/components/library/BatterAlert";
import { Action, ReactContext, ReactProps } from "@/types/react";

type AlertAction = Action & {
  type: "setAlert" | "hideAlert";
  message?: string;
  variant?: AlertVariant;
  interval?: number;
};

type AlertState = ReactContext<AlertAction> & {
  message: string;
  visible: boolean;
  interval: number;
  variant?: AlertVariant;
};

const initialState: AlertState = {
  dispatch: () => {},
  message: "",
  visible: false,
  interval: 0
};

const AlertContext = createContext(initialState);

const reducer = (state: AlertState, action: AlertAction): AlertState => {
  // instance
  const { type, variant, message = "", interval = 0 } = action;

  // return state
  switch (type) {
    case "setAlert":
      return {
        ...state,
        visible: true,
        message,
        variant,
        interval
      };
    case "hideAlert":
      return {
        ...state,
        visible: false
      };
    default:
      return state;
  }
};

const AlertProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AlertContext.Provider value={{ ...state, dispatch }}>{children}</AlertContext.Provider>;
};

export { AlertContext, AlertProvider };
