import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cva, type VariantProps } from "class-variance-authority";
import { forwardRef } from "react";
import { cn } from "@/utils/core";

const alertVariants = cva(
  "batter-alert relative w-full rounded-lg border p-4 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.1)] [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4",
  {
    variants: {
      variant: {
        primary: "bg-[#cfe2ff] text-[#052c65] border-[#9ec5fe]",
        secondary: "bg-[#e2e3e5] text-[#2b2f32] border-[#c4c8cb]",
        success: "bg-[#d1e7dd] text-[#0a3622] border-[#a3cfbb]",
        danger: "bg-[#f8d7da] text-[#58151c] border-[#f1aeb5]",
        warning: "bg-[#fff3cd] text-[#664d03] border-[#ffe69c]",
        info: "bg-[#cff4fc] text-[#055160] border-[#9eeaf9]"
      }
    },
    defaultVariants: {
      variant: "primary"
    }
  }
);

type AlertVariant = Exclude<VariantProps<typeof alertVariants>["variant"], null | undefined>;

type BatterAlertProps = React.ComponentPropsWithoutRef<"div"> & {
  variant?: AlertVariant;
  dismissable?: boolean;
  onRemove?: () => void;
};

const BatterAlert = forwardRef<HTMLDivElement, BatterAlertProps>(
  ({ variant = "primary", children, className, dismissable = false, onRemove, ...props }, ref) => (
    <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props}>
      {children}
      {dismissable && (
        <div className="absolute top-3.5 right-2">
          <FontAwesomeIcon icon={faTimesCircle} onClick={onRemove} className="cursor-pointer" />
        </div>
      )}
    </div>
  )
);

BatterAlert.displayName = "BatterAlert";

export { BatterAlert, type AlertVariant, type BatterAlertProps };
